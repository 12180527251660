/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Box,
  Heading,
  Flex,
  FormHelperText,
  Button,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { motion } from 'framer-motion';
import { navigate } from 'gatsby';
import { PrimaryButton } from '../../buttons';
import 'react-toastify/dist/ReactToastify.css';

interface ForgotPasswordProps {
  email: string;
}
interface ForgotPasswordConfProps {
  email: string;
  code: string;
  newPass: string;
  newPassConfirmation: string;
}

const ForgotPassword = (): JSX.Element => {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const password = React.useRef({});

  const submitProps = useForm<ForgotPasswordProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const confirmProps = useForm<ForgotPasswordConfProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  password.current = confirmProps.watch('newPass', '');

  const onSubmit = async ({ email }: ForgotPasswordProps) => {
    try {
      setLoading(true);
      await Auth.forgotPassword(email);
      toast.success(`Email sent!`);
      setSubmitted(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(`Operation failed, ${error.message}`);
    }
  };

  const onConfirm = async ({ code, newPass }: ForgotPasswordConfProps) => {
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(
        submitProps.watch('email'),
        code,
        newPass
      );
      toast({
        title: `Password Changed`,
        variant: 'subtle',
        position: 'bottom',
        status: 'success',
        isClosable: true,
      });
      navigate('/signin');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Failed to change password, ${error.message}`,
        variant: 'subtle',
        position: 'bottom',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const backtoLogin = () => {
    navigate('/signin');
  };

  const variants = {
    init: { display: 'none' },
    closed: { height: '0', opacity: 0, display: 'none' },
    open: {
      height: '100%',
      opacity: 1,
      display: 'block',
      x: '0%',
    },
  };

  return (
    <Box>
      <motion.div
        initial="closed"
        animate={!submitted ? 'open' : 'closed'}
        variants={variants}
      >
        <Flex
          justify={['space-around', 'space-evenly']}
          direction={['column-reverse', 'row']}
          marginY={['10vh', '21vh']}
        >
          <Box
            bgColor="white"
            rounded="md"
            padding="20px"
            maxH="175px"
            w="100%"
          >
            <form action="submit" onSubmit={submitProps.handleSubmit(onSubmit)}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  height="50px"
                  marginBottom="10px"
                  {...submitProps.register('email', {
                    required: true,
                  })}
                  type="email"
                  placeholder="Email"
                />
                <FormHelperText color="red.500">
                  {submitProps.formState.errors.email && (
                    <span>This field is required</span>
                  )}
                </FormHelperText>
              </FormControl>
              <Flex justify="space-between">
                <Button
                  color="#283447"
                  variant="ghost"
                  onClick={backtoLogin}
                  type="button"
                >
                  Back to Login
                </Button>
                <PrimaryButton isLoading={loading} type="submit">
                  Submit
                </PrimaryButton>
              </Flex>
            </form>
          </Box>
          <Box
            marginX="75px"
            padding="20px"
            height="auto"
            maxH="400px"
            w="100%"
          >
            <Heading textColor="#fff" fontSize={['30pt', '60pt']}>
              Reset Your Password
            </Heading>
            <Text fontSize={['15pt', '20pt']}>
              You'll get an email with instructions
            </Text>
          </Box>
        </Flex>
      </motion.div>
      <motion.div
        initial="closed"
        animate={submitted ? 'open' : 'closed'}
        transition={{
          duration: 0.5,
        }}
        variants={variants}
      >
        <Flex
          justify={['space-around', 'space-evenly']}
          direction={['column-reverse', 'row']}
          marginY={['10vh', '21vh']}
        >
          <Box bgColor="white" rounded="md" padding="20px" w="100%">
            <form
              autoComplete="off"
              action="submit"
              onSubmit={confirmProps.handleSubmit(onConfirm)}
            >
              <FormControl id="code" isRequired>
                <FormLabel>Code</FormLabel>
                <Input
                  id="code"
                  name="code"
                  autoComplete="off"
                  height="50px"
                  {...confirmProps.register('code', {
                    required: true,
                  })}
                  placeholder="Code"
                />
                <FormHelperText color="red.500">
                  {confirmProps.formState.errors.code && (
                    <span>This field is required</span>
                  )}
                </FormHelperText>
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>New Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  autoComplete="off"
                  height="50px"
                  {...confirmProps.register('newPass', {
                    required: true,
                    minLength: 8,
                    pattern:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                  })}
                  type="password"
                  placeholder="New Password"
                />
                <FormHelperText color="red.500">
                  {confirmProps.formState.errors.newPass && (
                    <span>This field is required</span>
                  )}
                </FormHelperText>
                <FormHelperText color="black">
                  <span>
                    Password should be at least 8 characters with a combination
                    of lowercase, and uppercase letters
                  </span>
                </FormHelperText>
              </FormControl>
              <FormControl id="password" name="password" isRequired>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  autoComplete="off"
                  height="50px"
                  {...confirmProps.register('newPassConfirmation', {
                    required: true,
                    minLength: 8,
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d](?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
                    validate: (value) =>
                      value === password.current ||
                      'The passwords do not match',
                  })}
                  type="password"
                  placeholder="Confirm New Password"
                />
                <FormHelperText color="red.500">
                  {confirmProps.formState.errors.newPassConfirmation && (
                    <span>Your password does not match</span>
                  )}
                </FormHelperText>
              </FormControl>
              <Flex marginTop="10px" justify="space-between">
                <PrimaryButton
                  paddingX="10px"
                  paddingY="5px"
                  isLoading={loading}
                  type="submit"
                >
                  Submit
                </PrimaryButton>
                {/* <SecondaryButton type="button">Back to Login</SecondaryButton> */}
              </Flex>
            </form>
          </Box>
          <Box padding="20px" height="auto" maxH="400px" w="100%">
            <Heading fontSize={['30pt', '60pt']}>Check Your Email!</Heading>
            <Text fontSize={['15pt', '20pt']}>
              Use the code in your email to reset your password
            </Text>
          </Box>
        </Flex>
      </motion.div>
      <ToastContainer theme="colored" />
    </Box>
  );
};

export default ForgotPassword;
