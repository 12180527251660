import * as React from 'react';
import Amplify from 'aws-amplify';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import SEO from '../components/seo/seo';
import awsmobile from '../utils/aws-exports';
import './css/forgot-password.css';
import ForgotPassword from '../components/forms/forgotPassword/forgotPassword';
import Navbar from '../components/nav/navbar/navbar';
import Footer from '../components/footer/footer';

const ForgotPasswordPage = (): JSX.Element => {
  Amplify.configure(awsmobile);
  return (
    <>
      <Box
        overflow="clip"
        height="100vh"
        bgImage="linear-gradient(rgba(79, 193, 255, 0.8),rgba(79, 193, 255, 0.8)) , url('https://openscreen-web-assets-prod.s3.amazonaws.com/background.png')"
      >
        <Navbar />
        <Box className="inner-container">
          <SEO title="Forgot Password" />
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              type: 'spring',
              mass: 0.35,
              stiffness: 75,
              duration: 0.3,
            }}
          >
            <ForgotPassword />
          </motion.div>
        </Box>
      </Box>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default ForgotPasswordPage;
